.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  font-family: "Lora";
}

.home-greeting {
  font-family: 'Playfair Display', serif;
  font-size: 80px;
  text-align: center;
}

.remainder {
  background-color: #BAABAB;
  position: absolute;
  z-index: -1;
  height: 10%;

}
.bongga {
  width: 35%;
  height: 100%;
  margin: auto;
}

.powerpoint {
  width: 100%;
  height: 100%;
  margin: auto;
}

.lead-carousel {
  width: 40%;
  height: 100%;
  margin: auto;
}

.carousel-caption {
  color: black;
}
